<template>
  <div class="modules_panel">
    <div class="page-title">
      <h3 style="display: flex"> <button class="aggregated_field_add_button" :class="openThis === 'Upload' ?'bg_2':''" @click="openThis = 'Upload'">Upload</button>
        <button class="aggregated_field_add_button" :class="openThis === 'Library'?'bg_2':''" @click="openThis = 'Library'">Library</button>
        <router-link v-if="openThis === 'Library'" :to="`/gen?d=${projId}`" class="black-text">
          <h2 style=" padding-left: 20px;display:flex;justify-content:space-around;" @click="setSelectedToProject">Project is {{projectName}}</h2>
        </router-link>
      </h3>
    </div>

    <div style="margin-left: 10px;" v-if="openThis === 'Upload'">
      <Modules
        v-model="modules"
        :current-key="currentKey"
        :modificator="'Upload'"
      />
      <button class="aggregated_field_add_button bg_4" style="margin-left: 0px;" @click="uploadToDatabase">Upload to DB</button>
      <h4 v-if="tmodules.length" style="margin-top: 30px;">Database includes modules with the same names:</h4>
      <Modules
        v-if="tmodules.length"
        v-model="tmodules"
        :current-key="currentKey"
        :modificator="'Libraries'"
      />
      <button v-if="tmodules.length" class="aggregated_field_add_button bg_5" style="margin-left: 0px;" @click="replaceToDatabase()">Replace in DB</button>

      <h4 v-if="dmodules.length" style="margin-top: 30px;">The following modules have defects and cannot be loaded</h4>
      <Modules
        v-if="dmodules.length"
        v-model="dmodules"
        :current-key="currentKey"
        :modificator="'Libraries'"
      />
    </div>
    <div style="margin-left: 10px;" v-if="openThis === 'Library'">

      <h4 style="cursor: pointer"
      @click="togglers.collections=!togglers.collections"
      >Collections {{!togglers.collections?'🡾':'🡼'}}</h4>
      <div v-show="togglers.collections">
        <div>
          <label
            class="aggregated_field_add_button"
            :class="setCollections.length?'bg_5':''"
            style="font-size: 14px; color:black"
            @click="setCollections=[]"
          >Clear</label>
        </div>
        <span
        v-for="(tg,index) in dbCollections"
        :key="`collection${index}`"
      >

           <label
             class="aggregated_field_add_button"
             :class="setCollections.includes(index)?'bg_1':''"
             style="font-size: 14px; color:black"
           >{{tg.name}}<input type="checkbox" :value="index" v-model="setCollections"></label>
      </span>
      </div>
      <h4 style="cursor: pointer"
        @click="togglers.tags=!togglers.tags"
      >Tags {{!togglers.tags?'🡾':'🡼'}}</h4>
      <div v-show="togglers.tags">
      <div>
        <label
          class="aggregated_field_add_button"
          :class="oneOf?'bg_2':'bg_4'"
          style="font-size: 14px; color:black"
        >{{oneOf?'One of the tags':'All of the tags'}}<input type="checkbox" v-model="oneOf"></label>
        <label
          class="aggregated_field_add_button"
          :class="tags.length?'bg_5':''"
          style="font-size: 14px; color:black"
          @click="tags=[]"
        >Clear</label><label
          class="aggregated_field_add_button"
          :class="tags.length?'bg_2':''"
          style="font-size: 14px; color:black"
          @click="tags=tagsLibrary"
        >Show all</label>
      </div>
      <span
              v-for="(tg,index) in tagsLibrary"
              :key="`tag${index}`"
              @contextmenu.prevent="packageTagEdit(tg)"

            >

           <label
             class="aggregated_field_add_button"
             :class="tags.includes(tg)?'bg_1':''"
             style="font-size: 14px; color:black"
           >{{tg}}<input type="checkbox" :value="tg" v-model="tags"></label>
      </span>
      </div>
      <div style="height: 70vh; padding-left: 5px; overflow-y:scroll; overflow-x: hidden ">
        <Modules
          v-model="lmodules"
          :modificator="'Libraries'"
          :current-key="currentKey"
          :key="theFetchedData"
          @altleft="route('/module?mid='+$event)"
        />
      </div>
      <div style="position:fixed; bottom: 5px; width: Calc(100% - 300px);display: block;">
      <button class="bg_3 aggregated_field_add_button" style="margin-left: 0px;" @click="setSelectedToProject">Set {{lmodules.filter(x=>x.selected===true).length}} selected to {{projectName}}</button>
      <button class="bg_5 aggregated_field_add_button" style="margin-left: 10px;" @click="lmodules.forEach(m=>m.selected=false)">Clear selection</button>
      <button class="bg_1 aggregated_field_add_button" style="margin-left: 10px;" @click="showModal('mCollection')">Add {{lmodules.filter(x=>x.selected===true).length}} selected to collection</button>
        <button class="bg_4 aggregated_field_add_button" style="margin-left: 10px; float: right;" @click="showModal('mTags')">Tags management</button>
      </div>
        <modal
        name="mCollection"
        :adaptive="true"
        width="500"
        height="auto"
      >
        <div class="v--modal-inner">
          <p class="h5 v--modal-title">Set collection</p>
          <a
            href="#"
            class="v--modal-close"
            @click.prevent="hideModal('mCollection')"
          >Close</a>
          <table v-if="dbCollections.length">
            <thead>
            <tr>
              <th>Name</th>
              <th>Access</th>
              <th>Amount</th>
              <th>Delete</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item,index) in dbCollections"
                :key="item.key">
              <td><button
                class="btn-small btn"
                @click="addToCollection(item.key,item.private)"
              >{{item.name}}</button></td>
              <td>{{item.private?'private':'public'}}</td>
              <td>{{item.keys.length}}</td>
              <td>
                <button @click="deleteDataFromDb('/collections/'+item.key,item.private,index)" class="btn-small btn">
                  <i  class="material-icons">delete</i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <p style="margin-top: 30px;" class="h5 v--modal-title">Or create new</p>
          <button style="margin-left: 0; width: 100px;" class="aggregated_field_add_button" :class="collection.private?'bg_2':'bg_4'" @click="collection.private=!collection.private">
            {{collection.private?'Private':'Public'}}</button>
          <input style="width:70%; margin-left: 20px;" type="text" v-model="collection.name" placeholder="collection name">
          <button
            class="btn-small btn"
            style="margin-top: 20px;"
            @click="createCollection"
          >Create</button>
        </div>
      </modal>
        <modal
        name="mTags"
        :adaptive="true"
        width="500"
        height="auto"
      >
        <div class="v--modal-inner">
          <p class="h5 v--modal-title">Set tags</p>
          <a
            href="#"
            class="v--modal-close"
            @click.prevent="hideModal('mTags')"
          >Close</a>
          <table v-if="tagsLibrary.filter(x=>x!=='other').length">
            <thead>
            <tr>
              <th>Name</th>
              <th>Delete</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item,index) in tagsLibrary.filter(x=>x!=='other')"
                :key="'tag_remove'+index">
              <td v-if="tagIsEdited !== index"><span
              >{{item}}</span></td>
              <td v-else>
                <input type="text" v-model="renamedTag">
              </td>
              <td>
                <button @click="deleteTheTag(item)" class="btn-small btn">
                  <i  class="material-icons">delete</i>
                </button>
              </td>
              <td>
                <button @click="tagIsEdited !== index?setEditTag(index,item):editTheTag(item)" class="btn-small btn">
                  <span>{{tagIsEdited === index?'Change':'Edit'}}</span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <p style="margin-top: 30px;" class="h5 v--modal-title">Or create new</p>
          <input style="width:70%; margin-right: 20px;" type="text" v-model="newTagName" placeholder="new tag name">
          <button
            class="btn-small btn"
            style="margin-top: 20px;"
            @click="addNewTag(newTagName)"
          >Create</button>
        </div>
      </modal>

    </div>

  </div>
</template>
<script>
import Modules from '../components/sections/Modules.vue'

export default {
  components: {
    Modules
  },
  data () {
    return {
      modules: [],
      tmodules: [],
      dmodules: [],
      lmodules: [{ selected: false, displayed: true, dataUri: '', tags: [], indexer: [] }],
      allModules: [],
      tags: ['basic'],
      openThis: 'Library',
      dselected: [],
      projectName: '',
      usedModules: [],
      currentKey: '',
      secondKeyModifier: null,
      tagsLibrary: [],
      tagsStorage: {},
      theFetchedData: false,
      oneOf: true,
      mCollections: [],
      collection: { name: '', private: true },
      dbCollections: [],
      setCollections: [],
      fullmodules: [],
      getModulesFromDB: false,
      togglers: {
        collections: false,
        tags: false
      },
      newTagName: '',
      tagIsEdited: null,
      renamedTag: ''
    }
  },
  computed: {
    projId () {
      return this.$store.getters.lastProject
    }
  },
  async mounted () {
    this.getLibraryData()
    this.getAllCollection()
    window.addEventListener('keydown', this.keyListener, false)
    window.addEventListener('keyup', e => {
      this.currentKey = null
      if (['Shift', 'Control', 'Alt', 'Meta'].includes(e.key)) {
        if (!this.secondKeyModifier) {
          this.keyModifier = null
        }
        if (this.keyModifier !== this.secondKeyModifier) {
          this.secondKeyModifier = null
        } else { this.keyModifier = null }
      }
    })
  },
  watch: {
    tags () {
      this.modulesFilter()
    },
    oneOf () {
      this.modulesFilter()
    },
    setCollections () {
      this.modulesFilter()
    },
    modules: {
      deep: true,
      async handler (value) {
        this.modulesCheck()
      }
    }
  },
  methods: {
    async modulesCheck () {
      const namesList = []
      const keyList = new Map()
      this.modules.forEach((m, index) => {
        if (m.svgString.includes('stroke')) {
          m.error = 'Unsupported lines'
        } else if (m.svgString.includes('transform')) {
          m.error = 'Has transformation'
        } else if (m.svgString.match(/fill="none"/g).length > 1) {
          m.error = 'Has invisible objects'
        } else if (m.svgString.includes('clip-path=')) {
          m.error = 'Has clipping mask'
        } else if (m.svgString.includes('fill="url')) {
          m.error = 'Has gradient or unsupported filling'
        } else if (m.svgString.includes('link:href=')) {
          m.error = 'Includes outer raster image'
        }
        if (m.error) {
          this.dmodules.push(m)
          this.modules.splice(index, 1)
        } else {
          namesList.push(m.fileName)
        }
      })
      const moduleStorage = JSON.parse(localStorage.modulesStorage)
      const namesStorage = moduleStorage.filter(t => namesList.includes(t.fileName))
      const fnames = []
      namesStorage.forEach(m => {
        fnames.push(m.fileName)
        keyList.set(m.fileName, m.indexer)
      })
      if (fnames.length > 0) {
        this.HustonWeHaveAProblem(fnames, keyList)
      }
    },
    HustonWeHaveAProblem (names, keyList) {
      this.modules.forEach((m, index) => {
        if (names.includes(m.fileName)) {
          m.lastKey = keyList.get(m.fileName)
          this.tmodules.push(m)
          this.modules.splice(index, 1)
        }
      })
    },
    async replaceToDatabase () {
      this.tmodules.forEach(m => {
        this.moduleReplace(m.lastKey, m.svgString, m.jpegString)
      })
      this.tmodules = []
    },
    async modulesFilter () {
      const indexes = []
      const subl = JSON.parse(localStorage.modulesStorage)
      subl.forEach((module, index) => {
        let numb = 0
        if (!this.oneOf) numb = module.tags.length - 1
        if (this.tags.filter(x => module.tags.includes(x)).length > numb) {
          indexes.push(module.indexer)
        }
      })
      this.setCollections.forEach(key => {
        subl.filter(m => this.dbCollections[key].keys.includes(m.indexer)).forEach((module, index) => {
          indexes.push(module.indexer)
        })
      })
      subl.forEach(m => { m.selected = false })
      let sm = subl.filter((element, index) => indexes.includes(element.indexer))
      sm = await this.getDataFromLocalModules(sm)
      sm.forEach(module => {
        if (this.usedModules.includes(module.indexer)) module.selected = true
      //   let numb = 0
      //   if (this.oneOf) numb = module.tags.length - 1
      //   if (this.tags.filter(x => module.tags.includes(x)).length > numb) { module.displayed = true } else { module.displayed = false }
      })
      this.lmodules = sm
    },
    async uploadToDatabase () {
      this.modules.forEach(m => {
        if (m.tags.length === 0) m.tags = ['other']
      })
      await this.$store.dispatch('saveChangesToTheModules', this.modules)
      this.modules = []
      await this.getLibraryData()
    },
    async setSelectedToProject () {
      const modules = []
      this.lmodules.filter(module => module.selected === true).forEach(module => {
        modules.push({ indexer: module.indexer, selected: false })
      })
      await this.$store.dispatch('setNewModules', { json: JSON.stringify(modules), prid: Number(this.$store.getters.lastProject) })
      await this.getLibraryData()
    },
    async getLibraryData () {
      await this.$store.dispatch('getTheModulesLibrary')
      this.getModulesFromDB = true
      const atags = await this.db_a('tags')
      this.tagsLibrary = []
      this.tagsStorage = {}
      atags.forEach(tg => {
        this.tagsStorage[tg.data] = tg.key
        this.tagsLibrary.push(tg.data)
      })
      await this.$store.dispatch('fetchProjects')
      await this.$store.dispatch('getLastProject')
      this.usedModules = await this.$store.dispatch('getUsedItems', {
        type: 'modules',
        prid: this.$store.getters.lastProject
      })
      const prjcts = JSON.parse(this.$store.getters.projects)
      const lastprjct = Number(this.$store.getters.lastProject)

      if (lastprjct) {
        this.projectName = prjcts.filter(f => Number(f.date) === lastprjct)[0].name
      }
      this.modulesFilter()
    },
    keyListener (e) {
      if (e.keyCode === 67) {
        if (this.currentKey === 'Alt') {
          e.preventDefault()
          this.togglers.collections = !this.togglers.collections
        }
      } if (e.keyCode === 84) {
        if (this.currentKey === 'Alt') {
          e.preventDefault()
          this.togglers.tags = !this.togglers.tags
        }
      }

      if (e.keyCode === 68) {
        e.preventDefault()
        if (this.currentKey === 'Ctrl') {
          this.lmodules.forEach(module => {
            module.selected = false
          })
        }
      }
      if (e.ctrlKey || e.keyCode === 224 || e.metaKey || e.keyCode === 91 || e.keyCode === 93) {
        if (['Shift', 'Alt'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Ctrl'
        } else {
          this.keyModifier = 'Ctrl'
        }
        this.currentKey = 'Ctrl'
      }
      if (e.altKey) {
        if (['Shift', 'Ctrl'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Alt'
        } else {
          this.keyModifier = 'Alt'
        }
        this.currentKey = 'Alt'
      }
      if (e.shiftKey) {
        if (['Ctrl', 'Alt'].includes(this.keyModifier)) {
          this.secondKeyModifier = 'Shift'
        } else {
          this.keyModifier = 'Shift'
        }
        this.currentKey = 'Shift'
      }
    },
    async getAllCollection () {
      await this.$store.dispatch('getAllCollectionData')
      this.dbCollections = this.$store.getters.data
    },
    async createCollection () {
      const cmod = []
      this.lmodules.filter(m => m.selected === true).forEach(m => cmod.push(m.indexer))
      await this.db_q('collections', { name: this.collection.name, keys: JSON.stringify(cmod) }, this.collection.private)
      this.hideModal('mCollection')
      this.collection.name = ''
      this.getAllCollection()
    },
    async addToCollection (key, priv) {
      const cmod = []
      this.lmodules.filter(m => m.selected === true).forEach(m => cmod.push(m.indexer))
      await this.db_p(`collections/${key}/keys`, cmod, priv)
      this.hideModal('mCollection')
      this.getAllCollection()
    },
    async db_a (table) {
      await this.$store.dispatch('getDataFromTable', table)
      return this.$store.getters.data
    },
    async db_q (table, data, user) {
      await this.$store.dispatch('saveDataToTable', { table: table, data: data, user: user })
    },
    async db_p (path, array, user) {
      await this.$store.dispatch('putArrayToTable', { path: path, array: array, user: user })
    },
    async db_d (table, id) {
      await this.$store.dispatch('deleteDataFromTable', { table: table, id: id })
    },
    async db_v (table, id) {
      await this.$store.dispatch('getValueFromTable', { table: table, id: id })
      return JSON.parse(this.$store.getters.data)
    },
    async packageTagEdit (tag) {
      const data = []
      this.lmodules.forEach(module => {
        if (module.selected) {
          let newTag = []
          module.tags = JSON.parse(localStorage.modulesStorage).filter(m => m.indexer === module.indexer)[0].tags
          if (this.currentKey === 'Alt') {
            if (module.tags.includes(tag)) {
              module.tags.splice(module.tags.indexOf(tag), 1)
              newTag = module.tags
              if (newTag.length === 0) newTag = JSON.stringify(['other'])
              data.push({ key: module.indexer, tags: newTag })
            }
          } else {
            if (!module.tags.includes(tag)) {
              newTag = module.tags.concat([tag])
              if (newTag !== ['other']) { newTag.splice(newTag.indexOf('other'), 1) }
              data.push({ key: module.indexer, tags: JSON.stringify(newTag) })
            }
          }
        }
      })
      this.$store.dispatch('packageTagEditor', data)
      await this.getLibraryData()
    },
    hideModal (name) {
      this.$modal.hide(name)
      window.addEventListener('keydown', this.keyListener, false)
    },
    showModal (name) {
      this.$modal.show(name)
      window.removeEventListener('keydown', this.keyListener, false)
    },
    selectCollectionsFromDB () {
    },
    async getDataFromLocalModules (modules) {
      let db
      const dbReq = await indexedDB.open('db', 1)
      dbReq.onsuccess = (event) => {
        db = event.target.result
        const tx = db.transaction(['modules'], 'readonly')
        const store = tx.objectStore('modules')
        const tagsStorage = JSON.parse(localStorage.modulesStorage)
        modules.forEach((module, index) => {
          const req = store.get(module.indexer)
          req.onsuccess = (event) => {
            const data = event.target.result
            if (data) {
              module.dataUri = data.dataUri
              module.tags = tagsStorage.filter(t => t.indexer === module.indexer)[0].tags
            } else {
              console.log(module.indexer + ' not found')
            }
            if (index === modules.length - 1) {
              this.theFetchedData = !this.theFetchedData
            }
          }
        })
      }
      return modules
    },
    cl (e) {
      console.log(e)
    },
    route (push) {
      this.$router.push(push)
    },
    deleteDataFromDb (path, user, index) {
      this.$store.dispatch('deleteItemFromTable', { path: path, user: user })
      this.dbCollections.splice(index, 1)
    },
    async deleteTheTag (tag) {
      const data = []
      const module = JSON.parse(localStorage.modulesStorage)
      module.forEach(m => {
        if (m.tags.includes(tag)) {
          m.tags.splice(m.tags.indexOf(tag), 1)
          data.push({ key: m.indexer, tags: m.tags })
        }
      })
      await this.$store.dispatch('packageTagEditor', data)
      const key = this.tagsStorage[tag]
      await this.$store.dispatch('deleteListElementFromDatabase', { type: 'tags', id: key })
      this.tagsLibrary.splice(this.tagsLibrary.indexOf(tag), 1)
      await this.getLibraryData()
    },
    addNewTag (tag) {
      this.$store.dispatch('saveDataToTable', { data: tag, table: 'tags', user: false })
      this.newTagName = ''
      this.tagsLibrary.push(tag)
      this.hideModal('mTags')
    },
    async editTheTag (name) {
      const oldTag = name
      const newTag = this.renamedTag
      const data = []
      const module = JSON.parse(localStorage.modulesStorage)
      module.forEach(m => {
        if (m.tags.includes(oldTag)) {
          m.tags[m.tags.indexOf(oldTag)] = newTag
          data.push({ key: m.indexer, tags: JSON.stringify(m.tags) })
        }
      })
      const key = this.tagsStorage[oldTag]
      await this.$store.dispatch('deleteListElementFromDatabase', { type: 'tags', id: key })
      await this.$store.dispatch('saveDataToTable', { data: newTag, table: 'tags', user: false })
      this.tagsLibrary.splice(this.tagsLibrary.indexOf(oldTag), 1).push(newTag)
      await this.$store.dispatch('packageTagEditor', data)
      await this.getLibraryData()
      this.tagIsEdited = null
      this.renamedTag = ''
    },
    setEditTag (index, item) {
      this.tagIsEdited = index
      this.renamedTag = item
    },
    async moduleReplace (key, svgString, jpegString) {
      const d = new Date()
      const now = d.getTime()
      await this.$store.dispatch('setNewDataToTable', {
        type: `fullmodules/${key}`,
        data: {
          svgString: svgString,
          dataUri: jpegString,
          upd: now
        }
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyListener, false)
  }
}
</script>
